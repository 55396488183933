import { Domain } from "frontend/lib/get-domain";
import {
  PersonEntry,
  PersonWrapperEntry,
  SchoolLeadershipPersonDetailsEntry,
} from "frontend/contentful/schema/semantics";
import { ContentfulIncludes, EntryRef } from "../schema/sys";
import { resolveEntry } from "./resolve-includes";
import { resolveEntries } from "./resolve-entries";
import { resolveEntryUrl } from "./resolve-entry-url";
import { isContentType } from "frontend/contentful/lib/is-content-type";
import { resolveIsPersonPastHbsFaculty } from "./resolve-is-person-past-hbs-faculty";
import { SiteEntry } from "../schema/universal";
import { getSiteId } from "frontend/lib/get-site-id";

interface ResolvePersonLinkArgs {
  entry?: EntryRef<
    PersonEntry | PersonWrapperEntry | SchoolLeadershipPersonDetailsEntry
  >;
  domain: Domain | undefined;
  site?: SiteEntry;
  includes: ContentfulIncludes;
}

export function resolvePersonLink({
  entry,
  domain,
  site,
  includes,
}: ResolvePersonLinkArgs) {
  let person = resolveEntry(includes, entry);
  if (!person) return "";
  let url = "";

  if (isContentType<PersonEntry>("person", person)) {
    const isHbsFaculty = resolveEntries({
      includes,
      refs: person.fields.personType,
    }).some((type) => type.fields.title === "HBS Faculty");

    let bioUrl = resolveEntry(includes, person.fields.bioURL)?.fields.url;
    if (domain && !bioUrl?.startsWith("https://")) {
      bioUrl = resolveEntryUrl({
        domain: domain,
        entry: resolveEntry(includes, person.fields.bioURL),
        includes,
      });
    }

    if (bioUrl) {
      url = bioUrl;
    } else if (isHbsFaculty && person?.fields.hbsEnterpriseID) {
      url = `https://www.hbs.edu/faculty/Pages/profile.aspx?facId=${person.fields.hbsEnterpriseID}`;
    }
  }

  if (
    isContentType<SchoolLeadershipPersonDetailsEntry>(
      "schoolLeadershipPersonDetails",
      person,
    )
  ) {
    url = resolveEntry(includes, person.fields.link)?.fields.url || "";
    person = resolveEntry(includes, person.fields.person);
  }

  let bioUrl = person
    ? resolveEntry(includes, person.fields.bioURL)
    : undefined;

  if (isContentType<PersonWrapperEntry>("personWrapper", person)) {
    if (!bioUrl) {
      bioUrl = resolveEntry(
        includes,
        resolveEntry(includes, person.fields.person)?.fields.bioURL,
      );
    }
  }

  if (!url && bioUrl && domain) {
    url = resolveEntryUrl({ domain: domain, entry: bioUrl, includes }) || "";
  }

  if (
    resolveIsPersonPastHbsFaculty({
      entry: isContentType<PersonWrapperEntry>("personWrapper", person)
        ? person.fields.person
        : person,
      includes,
    })
  ) {
    return "";
  }

  const siteId = getSiteId(resolveEntry(includes, site));

  if (
    !url &&
    isContentType<PersonWrapperEntry>("personWrapper", person) &&
    siteId === "working-knowledge"
  ) {
    const personType = resolveEntry(includes, person.fields.person)?.fields
      .personType;
    const isFaculty = resolveEntries({
      includes,
      refs: personType,
    }).some((type) => type.fields.title === "HBS Faculty");

    if (isFaculty) {
      const personWrapperUrl = resolveEntryUrl({
        entry: resolveEntry(includes, person.fields.bioURL),
        domain: "library",
        includes: includes,
        absolute: true,
      });

      if (personWrapperUrl) {
        url = personWrapperUrl;
      }
    }
  }

  return url;
}
