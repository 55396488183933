import { ProfileTopperProps } from "design-system/components/toppers/profile-topper/profile-topper";
import { WKProfileTopperEntry } from "frontend/contentful/schema/toppers";
import { useContentful } from "frontend/hooks/use-contentful";
import { createContentfulProps } from "frontend/contentful/lib/create-contentful-props";
import { createPersonImageProps } from "../primitives/media-asset.props";
import { RenderContentfulRichText } from "../utils/render-contentful-rich-text";

export const createWKProfileTopperProps = createContentfulProps<
  WKProfileTopperEntry,
  ProfileTopperProps
>(({ entry }) => {
  const { getEntry, getEntries, getPersonName, getPersonLink } =
    useContentful();

  const props: ProfileTopperProps = {};

  const profile = getEntry(entry.fields.person);
  const originalEntry = profile;

  props.theme = entry.fields.theme;

  const jobTitles: Array<string> = [];
  let mediaAsset = undefined;

  let units: string | undefined = undefined;

  if (profile) {
    getEntries(profile.fields.academicUnit)?.forEach((unit) => {
      if (unit?.fields.title) {
        units = [units, unit.fields.title].filter(Boolean).join(", ");
      }
    });
  }

  if (profile) {
    if (profile.fields.jobTitles) jobTitles.push(...profile.fields.jobTitles);

    profile.fields?.secondaryPosition?.forEach((position) => {
      if (position) {
        jobTitles.push(position);
      }
    });

    props.titles = jobTitles;
    props.name = getPersonName(originalEntry);

    if (!mediaAsset) mediaAsset = createPersonImageProps(profile);

    if (mediaAsset?.image?.src) {
      props.portrait = {
        ...mediaAsset.image,
        width: "245px",
        height: "259px",
      };
    }

    let profileLink = getPersonLink(profile);

    if (!profileLink) {
      const isFaculty = getEntries(profile.fields?.personType).some(
        (type) => type.fields.title === "HBS Faculty",
      );
      if (isFaculty && profile.fields?.hbsEnterpriseID) {
        profileLink = `https://www.hbs.edu/faculty/Pages/profile.aspx?facId=${profile.fields.hbsEnterpriseID}`;
      }
    }

    if (profileLink) {
      props.bioLink = {
        href: profileLink,
        children: "Read full bio",
      };
    }

    if (profile.fields.bio) {
      props.bio = <RenderContentfulRichText document={profile.fields.bio} />;
    }
  }

  return props;
});
